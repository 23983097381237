import { graphql } from 'gatsby'
import React from 'react'

import { Header, SectionContainer, StatePageContainer, Footer, StateContent } from '@alteos/ui'

import Layout from '../components/Layout'
import { IIndividualCTAContent, IHeaderContent, IImage, IFooterContent } from '../types/IBaseContentful'
import { getCtaParams } from '../utils/contentMaps/ctaContent'

import '@alteos/ui/dist/style.css'

export interface IStateData {
  contentfulStatePageContent: IContentfulStatePageContent
}

export interface IContentfulStatePageContent {
  slug: string
  headerContent: IHeaderContent
  statusTitle: string
  statusDescription: IStatusDescription
  statusImage: IImage
  mainRedirectCta: IIndividualCTAContent
  subRedirectCta?: null
  footerContent: IFooterContent
}

export interface IStatusDescription {
  statusDescription: string
}

interface IStateTemplateData {
  data: IStateData
}

const mapContent = (
  data: IContentfulStatePageContent,
  setPopupState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const header = {
    items: data.headerContent.content.map((item) => getCtaParams(item)),
  }

  const content = {
    title: data.statusTitle,
    content: data.statusDescription.statusDescription,
    image: data.statusImage,
    ctaButton: getCtaParams(data.mainRedirectCta, setPopupState),
    ctaLink: getCtaParams(data.subRedirectCta, setPopupState),
  }

  const footer = {
    copyright: data.footerContent.copyright.copyright,
    items: data.footerContent.content.map((item) => getCtaParams(item, setPopupState)),
  }

  return {
    slug: data.slug.replace('/', ''),
    header,
    content,
    footer,
  }
}

const StatePage = ({ data }: IStateTemplateData): JSX.Element => (
  <Layout>
    {({ setPopupState }) => {
      const content = mapContent(data.contentfulStatePageContent, setPopupState)

      return (
        <StatePageContainer>
          <>
            <div>
              <SectionContainer noPaddingTop noPaddingBottom noGrid dataTest="SectionContainer">
                <Header isLight={false} />
              </SectionContainer>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StateContent {...content.content} dataTest={content.slug} />
              </div>
            </div>
            <Footer {...content.footer} />
          </>
        </StatePageContainer>
      )
    }}
  </Layout>
)

export const query = graphql`
  query ($slug: String!) {
    contentfulStatePageContent(slug: { eq: $slug }) {
      slug
      headerContent {
        content {
          ctaLabel
          ctaTo
        }
      }
      statusTitle
      statusDescription {
        statusDescription
      }
      statusImage {
        file {
          url
        }
      }
      mainRedirectCta {
        ctaTo
        ctaLabel
      }
      subRedirectCta {
        ctaTo
        ctaLabel
      }
      footerContent {
        copyright {
          copyright
        }
        content {
          ctaLabel
          ctaTo
        }
      }
    }
  }
`

export default StatePage
